<template>
  <list-content
      v-bind:rows-data="tableData"
      v-bind:columns="tableColumns"
      v-bind:add-label="pageContent.add_label"
      v-bind:title="pageContent.title"
      v-bind:new-uri="pageContent.newUri"
      v-bind:edit-uri="pageContent.editUri"
      v-bind:meta="meta"
      v-bind:show-message="showMessage"
      v-bind:message="message"
      @handlePageChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleSearchData="performSearch"
      @handleDeleteData="handleDelete"
  ></list-content>
</template>

<script>
import UserService from "@/api/users.service";
import ListContent from "@/components/ListContent";
export default {
  name: "Users",
  components: {ListContent},
  data() {
    return {
      tableColumns: [
          {'key':'name', 'label':'Name', 'sortable':true, 'is_image': false, 'width':''},
        {'key':'email', 'label':'Email', 'sortable':true, 'is_image': false, 'width':''},
        {'key':'websiteNames', 'label':'Websites', 'sortable':true, 'is_image': false, 'width':''},
        {'key':'domainNames', 'label':'Domains', 'sortable':true, 'is_image': false, 'width':''},
        {'key':'websiteTheme', 'label':'Template', 'sortable':true, 'is_image': false, 'width':''},
        {'key':'websiteThemePath', 'label':'Template Path', 'sortable':true, 'is_image': false, 'width':''},
        {'key':'subscriptionName', 'label':'Subscription', 'sortable':true, 'is_image': false, 'width':''},
        {'key':'created_at', 'label':'Created At', 'sortable':true, 'is_image': false, 'width':''},
      ],
      pageContent: {
        'add_label': 'Add User',
        'title': 'Users',
        'newUri': '/users/add',        
        'editUri':'/users/edit'
      },
      tableData: [],
      page: 1,
      search: '',
      meta: Object,
      showMessage: false,
      message: {message:'', messageType:'success', effect:'dark'}
    }
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      return UserService.users(this.page, this.search).then(response => {
        this.meta = response.data.meta;
        this.tableData = response.data.data;

        // Websites
        this.tableData = this.tableData.map(function (r){
          return r = {...r, websiteNames: r.websites.map(w => w.name).join('<br>')}//r.websites[0].name
        })

        // subscriptionName
        this.tableData = this.tableData.map(function (r){
          return r = {...r, subscriptionName: r.subscriptions.map(s => s.name).join('<br>')}//r.websites[0].name
        })

        // Website Theme
        this.tableData = this.tableData.map(function (r){
          return r = {...r, websiteTheme: r.websites.map(
                function (w){
                  return w.theme.name
                }
            ).join('<br>')
          }
        })

        // Website Theme Path
        this.tableData = this.tableData.map(function (r){
          return r = {...r, websiteThemePath: r.websites.map(
                function (w){
                  return '<a href="/template/edit/'+w.theme.id+'"> ' + w.theme.path + '</a>'
                }
            ).join('<br>')
          }
        })

        // Domains
        this.tableData = this.tableData.map(function (r){
          return r = {...r,
            domainNames:
                r.websites.map(
                    function (w){
                      return w.domains.map(d => '<a href="//'+d.domain+'" target="_blank"> ' + d.domain + '</a>').join('<br>')
                    }
                ).join(', ')
          }
        })
      });
    },
    performSearch(search) {
      this.search = search.name;
      this.getTableData();
    },
    handleDelete(data) {
      let deleteRow = data.row;
      this.showMessage = false;
      if (data.indexNo >= 0) {
        return UserService.delete(deleteRow).then(response => {
          if(response.status === 201) {
            this.message.message = "User deleted successfully";
            this.message.messageType = "success";
            this.message.effect = "dark";
            this.showMessage = true;
            this.getTableData();
          }
        });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTableData();
    },
    handleSizeChange(val){
      this.getTableData();
    }
  }
}
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
</style>
